import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    backgroundColor: colors.space04,
    backgroundImage:
      'url("//bridebook.imgix.net/assets/header-bg-desktop.jpg?auto=compress,format")',
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    minHeight: '250px',
    alignContent: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    maxWidthMobile: {
      minHeight: '125px',
    },
  },
});

export default styles;
