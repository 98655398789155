import { FelaCSS, alpha, colors } from '@bridebook/ui';

interface IStyles {
  task: FelaCSS;
  nextTask: FelaCSS;
  row: FelaCSS & { '> div > div': FelaCSS };
  taskName: FelaCSS;
}

const styles = (): IStyles => ({
  task: {
    width: '100%',
    minHeight: '90px',
    borderRadius: '4px',
    backgroundColor: colors.white,
    boxShadow: `0 0 6px 0 ${alpha(colors.black, 15)}`,
    alignContent: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingBottom: '12px',
    paddingTop: '12px',

    maxWidthMobile: {
      width: '100%',
    },
  },

  nextTask: {
    color: colors.mint,
    fontDefaultSemiBold: 14,
    lineHeight: '17px',
    textTransform: 'uppercase',
  },

  row: {
    justifyContent: 'left',
    flexDirection: 'row',
    paddingTop: '5px',

    '> div > div': {
      backgroundColor: 'transparent',
      color: 'red',
    },
  },

  taskName: { paddingLeft: '13px', fontDefault: 16 },
});

export default styles;
